// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
// components
import { Grid, Modal, Message, Icon } from 'semantic-ui-react'
import Sticky from 'react-stickynode'
import EditPageMenu from 'components/menus/EditPageMenu'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { TagForm } from './Form'
import TagCard from './Card'

// redux
import { fetchTag } from 'redux/entities/actions'
import { getTag } from 'redux/entities/selectors'

const mapStateToProps = (state, props) => ({
  fromStore: {
    tag: props.params.id && getTag(state, { id: props.params.id }),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    fetchTag: (id) => dispatch(fetchTag(id)),
  },
})

export class TagAdd extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      tag: PropTypes.immutable.record,
    }).isRequired,
    actions: PropTypes.object,
    params: PropTypes.object,
  }

  UNSAFE_componentWillMount() {
    const { actions, params } = this.props
    this.setState({ error: null, initialized: !params.id })
    if (params && params.id) actions.fetchTag(params.id).then(() => this.setState({ initialized: true }))
  }

  getMenuItems = (t) => [
    { text: t('Tags::Summary::Title'), value: 'title' },
    { text: t('Tags::Summary::Category'), value: 'category' },
    { text: t('Tags::Summary::Description'), value: 'description' },
  ]

  closeErrorModal = () => this.setState({ error: null })
  closeSuccessModal = () => this.setState({ success: undefined })
  onSaved = ({ error, success, title }) => this.setState({ error, success, title })

  render() {
    const {
      props: {
        t,
        fromStore: { tag },
      },
      state: { error, success, title },
    } = this

    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={3}>
            <Sticky>
              <EditPageMenu listName='tags' menuItems={this.getMenuItems(t)} />
            </Sticky>
          </Grid.Column>
          <Grid.Column width={8}>
            <TagForm tag={tag} />
          </Grid.Column>
          <Grid.Column width={3}>
            <Sticky>
              <TagCard tag={tag} onSaved={this.onSaved} />
            </Sticky>
          </Grid.Column>
        </Grid.Row>

        <ApiErrorMessage open={!!error} onClose={this.closeErrorModal} error={error} modal />
        <Modal open={success} onClose={this.closeSuccessModal}>
          <Message success>
            <Icon name='thumbs outline up' color='green' style={{ marginRight: '8px' }} />
            {t("Tags::Summary::Well done! You've just saved tag '{{title}}'", { title })}
          </Message>
        </Modal>
      </Grid>
    )
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(TagAdd))

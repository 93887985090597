import React, { useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { getCategories } from 'redux/entities/selectors'
import './index.css'

export function TagForm({ tag }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const categories = useSelector(getCategories)
  const form = useSelector((state) => getForm(state, 'tag'))

  useEffect(() => {
    dispatch(
      createForm({
        formName: 'tag',
        initialState: {
          title: tag?.title || undefined,
          category_id: tag?.category_id || undefined,
          description: tag?.description || undefined,
        },
      }),
    )
    return () => dispatch(removeForm({ formName: 'tag' }))
  }, [dispatch, tag?.category_id, tag?.title, tag?.description])

  const options = useMemo(
    () =>
      categories
        .toList()
        .sortBy((category) => category.name)
        .map(({ id, name }) => ({ value: id, text: name }))
        .toJS(),
    [categories],
  )

  function handleChange(_, { value, name }) {
    dispatch(mergeInForm({ formName: 'tag', value: { [name]: value } }))
  }

  return (
    <Form className='vz-tag-add-form'>
      <section id='title'>
        <h3 className='section-title'>{t('Tags::Summary::Title')}</h3>
        <Form.Input name='title' value={form.get('title', '')} onChange={handleChange} />
      </section>
      <section id='category'>
        <h3 className='section-title'>{t('Tags::Summary::Category')}</h3>
        <Form.Select
          name='category_id'
          value={form.get('category_id', '')}
          onChange={handleChange}
          search
          options={options}
        />
      </section>
      <section id='description'>
        <h3 className='section-title'>{t('Tags::Summary::Description')}</h3>
        <Form.TextArea name='description' value={form.get('description', '')} onChange={handleChange} />
      </section>
    </Form>
  )
}

TagForm.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    category_id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

import { schema } from 'normalizr'
import { Record, List } from 'immutable'
import { tags } from './tags'

const _schema = new schema.Entity('categories', {
  tags: [tags.schema],
})

const _Record = Record({
  id: undefined,
  name: undefined,
  original_name: undefined,
  description: undefined,
  color: undefined,
  tags: new List(),
  visibility: undefined,
  archived_at: undefined,
})

export const categories = {
  schema: _schema,
  Record: _Record,
}

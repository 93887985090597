import settings from 'settings'
import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const categoriesActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'categories' })
export const fetchCategories = ({ offset = 0, size = settings.defaultPaginationSize } = {}) =>
  categoriesActions.fetch({
    url: '/api/categories',
    schema: { categories: [schemas.categories] },
    query: {
      offset,
      size,
    },
  })

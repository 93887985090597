// libs
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCategory } from 'redux/entities/selectors'
// components
import { Link } from 'react-router'
import { Table, Button } from 'semantic-ui-react'

export function TagRow({ tag }) {
  const { t } = useTranslation()
  const category = useSelector((state) => getCategory(state, { id: tag.category_id }))

  return (
    <Table.Row>
      <Table.Cell>{tag.id}</Table.Cell>
      <Table.Cell>{tag.title}</Table.Cell>
      <Table.Cell>{category.name}</Table.Cell>
      <Table.Cell>
        <Button primary compact as={Link} to={`tags/edit/${tag.id}`}>
          {t('Tags::Edit')}
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

TagRow.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    category_id: PropTypes.number,
  }).isRequired,
}

// libs
import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { TagRow } from './Row'
// redux
import { getLocation } from 'redux/reducers/router'
import { getTagsList, getTagsError, fetchingTags, getTagsTotal } from 'redux/entities/selectors'
import { fetchTags } from 'redux/entities/actions'

export default function TagList() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const error = useSelector(getTagsError)
  const loading = useSelector(fetchingTags)
  const tags = useSelector(getTagsList)
  const total = useSelector(getTagsTotal)
  const location = useSelector(getLocation)

  const headers = useMemo(
    () => [
      { label: t('Tags::Id') },
      { label: t('Tags::Title'), field: 'tag' },
      { label: t('Tags::Category') },
      { label: t('Tags::Actions') },
    ],
    [t],
  )

  useEffect(() => {
    // fetch tags tp retrieve metadata
    dispatch(fetchTags())
  }, [dispatch])

  return (
    <Paginate count={total} previous next loading={loading}>
      <List striped celled sortable headers={headers} location={location}>
        {tags.toList().map((tag) => (
          <TagRow key={tag.id} tag={tag} />
        ))}
      </List>
      <ApiErrorMessage error={error} modal />
    </Paginate>
  )
}
